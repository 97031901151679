var checkPhone = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('手机号不能为空'));
  } else {
    const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
    // console.log(reg.test(val ue));
    if (reg.test(value)) {
      callback();
    } else {
      return callback(new Error('请输入正确的手机号'));
    }
  }
};
export const phone = [{validator: checkPhone, trigger: 'blur',required: true}]

export const idCard =  [{
  required: true,
  message: '身份证号码不能为空',
  trigger: 'blur'
}, {
  pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
  message: '身份证号码格式不正确',
  trigger: 'blur'
}]


export const postCode =  [{
  pattern: /^[1-9]\d{5}$/,
  message: '邮编格式不正确',
  trigger: 'blur'
}]

export const  practiceno = [{
  required: true,
  message: '执业证编号不能为空',
  trigger: 'blur'},
  {
    pattern: /^\d{17}$/,
    message: '执业证编号格式不正确',
    trigger: 'blur'
}]

export const  practiceno_F = [{
  required: true,
  message: '执业证编号不能为空',
  trigger: 'blur'},
  {
    // pattern: /^\d{18}$/,/[0-9A-Z]{18}/
    pattern: /^[0-9A-Za-z]{18}$/,
    message: '执业证编号格式不正确',
    trigger: 'blur'
  }]

export const  password = [{
  required: true,
  message: '密码不能为空',
  trigger: 'blur'},
  {
    pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/,
    message: '密码必须由字母和数字组成，长度为6-10位，字母不区分大小写',
    trigger: 'blur'
  }]


