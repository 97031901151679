<template>
  <div>
    <!--    主体律所列表-->
    <div class="app-container">
      <el-row
        style="padding-bottom: 15px"
        type="flex"
        align="middle"
        justify="space-between"
        stripe
      >
        <el-col :span="3">
          <b>律所列表</b>
        </el-col>

        <el-col :span="3">
          <el-button type="success" @click="handleAdd" icon="el-icon-plus" size="small">新增律所</el-button>
        </el-col>
        <!-- 律所名称搜索框 -->
        <el-col :span="2"  > 律所名称:  </el-col>
        <el-col :span="4" :pull="1">
          <el-input
            placeholder="请输入律所名称"
            v-model="searchFirmName"
            size="small"
          >
          </el-input>
        </el-col>

        <el-col :span="6" :pull="1" >

          <el-button type="primary" @click="handleSearch" icon="el-icon-search" size="small">搜索</el-button>
          <el-button @click="clearInput" size="small">重置</el-button>


        </el-col>
      </el-row>
      <el-table
        v-loading="loading"
        stripe
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background: '#eee', color: '#333' }"
      >
        <el-table-column prop="dname" align="center" label="律所名称"></el-table-column>
        <el-table-column prop="practiceno" align="center" label="组织机构代码证"></el-table-column>
        <el-table-column prop="form" align="center" label="组织形式"  width="150"></el-table-column>
        <el-table-column prop="director" align="center" label="主任名字"  width="120"></el-table-column>
        <el-table-column prop="phone" align="center" label="座机" width="120"></el-table-column>

        <el-table-column label="操作" align="center" fixed="right" width="400">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-more"
              @click="handleDetail(scope.$index, scope.row)"
            >详情</el-button>
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-refresh"
              @click="handleRest(scope.$index, scope.row)"
            >
              重置密码</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="handleDelete(scope.$index, scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--      分页插件-->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        layout="total, prev, pager, next"
        :page-size="10"
        :total="total"
        style="margin-top: 20px; text-align: right">
      </el-pagination>
    </div>

    <!--    屏幕打印检查数据-->
<!--    <h1>修改：{{editForm}}</h1>-->
<!--    <h2>添加：{{addForm}}</h2>-->

    <!--    详情探弹窗-->
    <el-dialog
      :fullscreen="true"
      title="律所详情"
      align="center"
      :visible.sync="ifDetail"
      width="50%">
      <el-row>
        <el-col :span="6" >
          <h3>律所名称</h3>
          <span>{{currentData.dname}}</span>
        </el-col>
        <el-col :span="6" :push="6" >
          <h3>成立时间</h3>
          <span>{{currentData.birthday}}</span>
        </el-col>
      </el-row>

      <el-divider></el-divider>

      <el-row>
        <el-col :span="6" >
          <h3>律师事务所编号</h3>
          <span>{{currentData.practiceno}}</span>
        </el-col>
        <el-col :span="6" :push="6">
          <h3>组织形式</h3>
          <span>{{currentData.form}}</span>
        </el-col>
      </el-row>

      <el-divider></el-divider>
      <el-row>
        <el-col :span="6" >
          <h3>主任名字</h3>
          <span>{{currentData.director}}</span>
        </el-col>
        <el-col :span="6" :push="6">
          <h3>座机</h3>
          <span>{{currentData.phone}}</span>
        </el-col>
      </el-row>

      <el-divider></el-divider>
      <el-row>
        <el-col :span="6" >
          <h3>内勤名字</h3>
          <span>{{currentData.staffName}}</span>
        </el-col>
        <el-col :span="6" :push="6">
          <h3>内勤手机</h3>
          <span>{{currentData.staffPhone}}</span>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="6" >
          <h3>地址</h3>
          <span>{{currentData.address}}</span>
        </el-col>
        <el-col :span="6" :push="6">
          <h3>邮编</h3>
          <span>{{currentData.postcode}}</span>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col :span="6" >
          <h3>邮箱</h3>
          <span>{{currentData.email}}</span>
        </el-col>
      </el-row>
    </el-dialog>

    <!--    修改信息弹窗-->

    <el-dialog
      :fullscreen="true"
      title="修改信息"
      align="center"
      :visible.sync="ifEdit"
      width="100%">
      <div style="width: 60%">
        <el-form ref="editForm" :model="editForm"  :rules="rules" label-width="15%">
          <el-form-item label="律所名称" prop="dname">
            <el-input v-model="editForm.dname" maxlength="20"  show-word-limit></el-input>
          </el-form-item>
          <!--        <el-form-item label="成立时间">-->
          <!--          <el-input v-model="form.create_time"></el-input>-->
          <!--        </el-form-item>-->
          <el-form-item label="成立时间">
            <el-col :span="11">
              <el-date-picker value-format="yyyy-MM-dd" type="date" placeholder="选择日期" v-model="editForm.birthday" style="width: 100%;"></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="组织机构代码证" prop="practiceno">
            <el-input v-model="editForm.practiceno" maxlength="18"  show-word-limit disabled></el-input>
          </el-form-item>

          <el-form-item label="组织形式">
            <el-input v-model="editForm.form" maxlength="10"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="主任名字">
            <el-input v-model="editForm.director" maxlength="10"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="座机">
            <el-input v-model="editForm.phone" maxlength="11"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="内勤名字">
            <el-input v-model="editForm.staffName" maxlength="10"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="内勤手机">
            <el-input v-model="editForm.staffPhone" maxlength="11"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="地址">
            <el-input v-model="editForm.address" type="textarea" maxlength="50"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="邮编" prop="postcode" maxlength="6"  show-word-limit>
            <el-input v-model="editForm.postcode"></el-input>
          </el-form-item>
          <!--        <el-form-item label="事务所邮箱">-->
          <!--          <el-input v-model="form.email"></el-input>-->
          <!--        </el-form-item>-->
          <el-form-item
            prop="email"
            label="邮箱">
            <el-input v-model="editForm.email"  maxlength="32"  show-word-limit></el-input>
          </el-form-item>
          <!--        <el-form-item label="部门名字">-->
          <!--          <el-input v-model="form.dname"></el-input>-->
          <!--        </el-form-item>-->
<!--          <el-form-item label="密码">-->
<!--            <el-input v-model="editForm.password"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item>
            <el-button size="small" type="primary" @click="beforEditSubmit('editForm')">确定修改</el-button>
            <el-button size="small" @click="editCancel">取消</el-button>
          </el-form-item>
        </el-form>
      </div>

    </el-dialog>



    <!--    新增律所弹窗-->

    <el-dialog
      :fullscreen="true"
      title="新增律所"
      align="center"
      :visible.sync="ifAdd"
      width="50%">

      <div style="width: 60%">
        <el-form style="margin-left: 10px" ref="addForm" :model="addForm" :rules="rules" label-width="15%">
          <el-form-item label="律所名称" prop="dname" >
            <el-input v-model="addForm.dname" maxlength="20"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="成立时间">
            <el-col :span="11">

              <el-date-picker  value-format="yyyy-MM-dd" type="date" placeholder="选择日期" v-model="addForm.birthday" style="width: 100%;"></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="组织机构代码证" prop="practiceno">
            <el-input v-model="addForm.practiceno" maxlength="18"  show-word-limit ></el-input>
          </el-form-item>

          <el-form-item label="组织形式" required>
            <el-input v-model="addForm.form" maxlength="10"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="主任名字">
            <el-input v-model="addForm.director" maxlength="10"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="座机">
            <el-input v-model="addForm.phone" maxlength="11"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="内勤名字">
            <el-input v-model="addForm.staffName" maxlength="10"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="内勤手机">
            <el-input v-model="addForm.staffPhone" maxlength="11"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="地址" required>
            <el-input v-model="addForm.address" type="textarea" maxlength="50"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="邮编" prop="postcode" maxlength="6"  show-word-limit>
            <el-input v-model="addForm.postcode"></el-input>
          </el-form-item>

          <el-form-item
            prop="email"
            label="邮箱">
            <el-input v-model="addForm.email"  maxlength="32"  show-word-limit></el-input>
          </el-form-item>

          <br>
          <span>新增律所后，律所可使用律所组织机构代码证号登录系统，初始密码为：123456</span>
          <el-form-item>
            <br>
            <el-button size="small" type="primary" @click="beforAddSubmit('addForm')">确定新增</el-button>
            <el-button size="small" @click="handCancelAdd">取消</el-button>
          </el-form-item>

        </el-form>

      </div>


    </el-dialog>

  </div>
</template>

<script>
  import { request } from '@/network/network'
  import { idCard, phone, postCode, practiceno_F, } from '@/utils/rules'
  import { user_political_status,user_degree, user_gender} from '@/utils/dict'
  import  {formatDate} from '@/utils/date'
  export default {
    data() {
      return {
        loading: true,
        searchFirmName:"",
        searchMap: "",
        tableData: [],
        total: 0,
        currentPage: 0,
        currentData:'',  //每行的数据
        //新增律师表单数据
        addForm:{
          birthday:'',
          practiceno:'',
          address:'',
          createTime:'',
          director:'',
          dname:'',
          email:'',
          form:'',
          phone:'',
          postcode:'',
          staffName:'',
          staffPhone:'',
        },
        //修改信息表单数据
        editForm:{
          birthday:'',
          practiceno:'',
          address:'',
          createTime:'',
          director:'',
          dname:'',
          email:'',
          form:'',
          phone:'',
          postcode:'',
          staffName:'',
          staffPhone:'',
        },
        rules:{
          practiceno: practiceno_F,
          dname: [
            { required: true, message: '请输入律所名称', trigger: 'blur' },
            { min: 1, max: 20, message: '不能多于20字', trigger: 'blur' }
          ],
          email: [{ type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }],
          staffName:  [
            { required: true, message: '请输入内勤人员姓名', trigger: 'blur' }],
          staffPhone: [{ required: true, message: '手机号不能为空', trigger: 'blur' }],
          postcode: postCode
        },
        ifAdd:false,
        ifEdit: false, //是否弹出详情框
        ifDetail:false,


      }
    },
    methods: {
      //分页插件绑定事件
      handleCurrentChange(val) {
        this.currentPage = val
        this.getFirms(this.searchMap, val)
      },
      // 重置搜索框
      clearInput() {
        this.searchFirmName = ""
        this.searchMap= ""
        this.getFirms()
      },
      //搜索按钮
      handleSearch(){
        this.searchMap = this.searchFirmName
        this.getFirms(this.searchMap)
      },
      //详情按钮
      handleDetail(index, row) {
        this.ifDetail = true
        this.currentData = row

      },
      // 新增按钮
      handleAdd(index,row){
        this.ifAdd = true
      },
      beforAddSubmit(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const loading = this.$loading({
              lock: true,
              text: '请稍等',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });

            this.checkAdd(this.addForm.practiceno)
              .then(res => {
              loading.close();
              if(res.data.length == 0){
                this.addSubmit()
              }
              else{
                this.$message({
                  message: "新增律所失败,该执业证号已存在！",
                  type: "error",
                  duration: 2000,
                });
              }
            })
              .catch((err) => {
                loading.close();
                this.$message({
                  message: "发生错误，新增律所失败！",
                  type: "error",
                  duration: 1000,
                });
                this.getFirms();
              });

          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 确定新增
      addSubmit(){
        const loading = this.$loading({
          lock: true,
          text: '请稍等',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.addFirm(this.addForm)
          .then((res) => {
            loading.close();
            this.$message({
              message: "新增律所成功！",
              type: "success",
              duration: 1000,
            });
            this.getFirms();
            this.addForm = {}
            this.ifAdd = false
          })
          .catch((err) => {
            loading.close();
            this.$message({
              message: "发生错误，新增律所失败！",
              type: "error",
              duration: 1000,
            });
            this.getFirms();
          });
      },
      //
      handCancelAdd(){
        this.ifAdd = false
        this.addForm = {}
      },
      // 重置密码按钮
      handleRest(index,row){
        this.$confirm("是否要重置该律所的密码?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {

            this.restPassword(row.id)
              .then((res) => {
                this.$message({
                  message: "重置成功！",
                  type: "success",
                  duration: 1000,
                });
                this.getFirms();

              })
              .catch((err) => {
                this.$message({
                  message: "发生错误，重置失败！",
                  type: "error",
                  duration: 1000,
                });
                this.getFirms();

              });
          })
          .catch(() => {
            this.$message({
              message: "操作取消！",
              type: "info",
              duration: 1000,
            });
          });

      },
      beforEditSubmit(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.editSubmit()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },

      // 编辑按钮
      handleEdit(index,row){
        this.ifEdit = true
        this.editForm = JSON.parse(JSON.stringify(row))
        //将该条数据传入editForm编辑信息表单中显示
      },
      //确定编辑
      editSubmit() {
        this.editFirm(this.editForm)
          .then((res) => {
            this.$message({
              message: "修改律所信息成功！",
              type: "success",
              duration: 1000,
            });
            this.getFirms();
            this.ifEdit = false
          })
          .catch((err) => {
            this.$message({
              message: "发生错误，修改律所信息失败！",
              type: "error",
              duration: 1000,
            });
            this.getFirms();
          });
      },
      editCancel() {
        this.getFirms()
        this.ifEdit = false

      },

      // 删除按钮
      handleDelete(index, row) {
        this.$confirm("是否要进行该删除操作?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.deleteFirm(row.id)
              .then((res) => {
                this.$message({
                  message: "删除成功！",
                  type: "success",
                  duration: 1000,
                });
                this.getFirms();
              })
              .catch((err) => {
                this.$message({
                  message: "发生错误，删除失败！",
                  type: "error",
                  duration: 1000,
                });
                this.getFirms();
              });
          })
          .catch(() => {

            this.$message({
              message: "操作取消！",
              type: "info",
              duration: 1000,
            });
          });
      },

      getFirms(dname, page){
        return request({
          url: 'department/departments',
          method:'get',
          params:{
            dname: dname,
            page: page
          }
        }).then(res => {
          this.total = res.data.total
          let temp = res.data.list
          this.tableData =temp;
          this.loading = false
          //   temp.map(item => {
          //
          //   item.birthday = formatDate(item.birthday)
          //
          //   return item
          // })

        })
      },
      addFirm(department){
        return request({
          url: '/department/addDep',
          method: 'post',
          data: department
        })
      },

      editFirm(department){
        return request({
          url: '/department/updateDep',
          method: 'post',
          data: department
        })
      },
      deleteFirm(id){
        return request({
          url: '/department/deleteDep',
          method: 'get',
          params: {
            id: id
          }
        })
      },
      restPassword(id){
        return request({
          url: '/department/restPassword',
          method: 'get',
          params: {
            id: id
          }
        })
      },
      checkAdd(practiceno){
        return request({
          url: '/department/checkAdd',
          method: 'get',
          params: {
            practiceno: practiceno
          }
        })

      },
    },
    created() {
      this.getFirms();
      this.adminInformation = JSON.parse(sessionStorage.getItem("information"))
    },
  };
</script>
<style scoped>
  .input-width {
    width: 80%;
  }
</style>
